import React, { RefObject } from "react";
import { Alert, Tab, Tabs } from "react-bootstrap";
import InventoryProductOverviewService, { IConfig } from "../../../services/inventory/InventoryProductOverviewService";
import OrdersService from "../../../services/orders/OrdersService";
import Deficits from "../../dashboard/Deficits/Deficits";
import { OrderStatusEnum } from "../../orders/OrderEnums";
import SellOrders from "../../orders/Orders/sell-orders/SellOrders";
import ShopPolicies from "../../shared/policy/ShopPolicies/ShopPolicies";
import CreateInventory from "../CreateInventory/CreateInventory";
import InventoryList from "../InventoriesList/InventoryList";
import InventoryPlans from "../InventoryPlans/InventoryPlans";
import ModalComponent from "../../shared/modal/Modal";
import SpinnerComponent from "../../shared/spinner/SpinnerComponent";

export default class Sell extends React.Component<{ onClose: () => void, productId: string }, { config: IConfig }> {
    refShopPolicies: RefObject<ShopPolicies>
    inventoryProductOverviewService: InventoryProductOverviewService;
    ordersService: OrdersService;

    constructor(props: any) {
        super(props)
        this.state = { config: undefined }
        this.refShopPolicies = React.createRef();
        this.inventoryProductOverviewService = new InventoryProductOverviewService();
        this.ordersService = new OrdersService();
    }

    componentDidMount(): void {
        this.loadPlan();
    }

    loadPlan = () => {
        this.inventoryProductOverviewService.getOrderingPlan(this.props.productId).then((response) => {
            this.setState({ config: { ...response } })
        })
    }

    render(): React.ReactNode {
        if (this.state.config === undefined)
            return <ModalComponent size="1000px" onClose={this.props.onClose}>
                <div>
                    <SpinnerComponent />
                </div>
            </ModalComponent>

        if (!this.state.config.canSell)
            return <ModalComponent size="1000px" onClose={this.props.onClose} title={`Sell - ${this.state.config.systemDetails.productTitle}`}>
                <div>
                    <Alert variant="danger">Sales on this product are restricted and you are not able to sell for this particular product.</Alert>
                </div>
            </ModalComponent >
        return <ModalComponent size="1000px" onClose={this.props.onClose} title={`Sell - ${this.state.config.systemDetails.productTitle}`}>
            <div>
                <Tabs className="mb-3" mountOnEnter={true} unmountOnExit={true}>
                    {
                        this.state.config.plansConfig && this.state.config.supplyMethod === 0 &&
                        <Tab eventKey="add" title="Add">
                            <CreateInventory productId={this.props.productId} />
                        </Tab>
                    }
                    <Tab eventKey="plan" title="Plan">
                        <InventoryPlans productId={this.props.productId} onUpdated={this.loadPlan} />
                    </Tab>

                    {
                        this.state.config.supplyMethod === 0 && <Tab eventKey="inventories" title="Inventories">
                            <InventoryList defaultFilter={{ productId: this.props.productId }} hideDontShowOnlyinLockRow />
                        </Tab>
                    }

                    {
                        this.state.config.supplyMethod === 0 && <Tab eventKey="incoming" title="Incoming">
                            <Deficits productId={this.props.productId} />
                        </Tab>
                    }

                    {
                        this.state.config.supplyMethod === 0 &&
                        <Tab eventKey="outgoing" title="Outgoing">
                            <SellOrders defaultFilters={{ status: OrderStatusEnum.Pending, productId: this.props.productId }} />
                        </Tab>
                    }
                </Tabs>
            </div>
        </ModalComponent >

    }
}