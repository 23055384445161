import { mapToDataOnly } from "../common/HttpUtility";
import IUpdateUserInformation from '../../models/dtos/identity/IUpdateUserInformationDTO'
import { IUserInformationDTO } from "../../models/dtos/identity/IUserInformationDTO";
import { IChangePasswordDTO } from "../../models/dtos/identity/IChangePasswordDTO";
import { SocialNetworkApiFactory } from "../../generated-api";
import { AccountApiFactory } from "../../generated-api/admin";
import { ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto, PasswordApiFactory, RegisterApiFactory, SecurityApiFactory, UserInformationApiFactory } from "../../generated-api/identity";
import { jwtDecode } from "jwt-decode";

export default class IdentityService {
    login(username: string, password: string, totp?: string | undefined): Promise<any> {
        return AccountApiFactory().identityAccountPost({
            userName: username,
            password: password,
            totp: totp
        });
    }
    forgotPassword(email: string, returnUrl: string): Promise<{}> {
        return mapToDataOnly(PasswordApiFactory().identityPasswordForgotPost(returnUrl, { email }));
    }

    resetPassword(data: {
        token: string,
        newPassowrd: string,
        userId: string
    }, returnUrl: string): Promise<{}> {
        return mapToDataOnly(PasswordApiFactory().identityPasswordForgotVerifyPost(returnUrl, data));
    }

    register(registerInformation: {
        password: string,
        email: string
        firstname: string
        lastname: string
    }, returnUrl: string): Promise<{}> {
        return mapToDataOnly(RegisterApiFactory().identityRegisterUserPost(returnUrl, registerInformation));
    }

    confirmationEmail(email: string, returnURL: string): Promise<{}> {
        return mapToDataOnly(SecurityApiFactory().identitySecurityConfirmEmailPut({ email, returnURL }));
    }

    getUserInformation(): Promise<any> {
        return AccountApiFactory().identityAccountInformationsGet(); 
        try {
            const token = localStorage.getItem("token");
            const shopId = localStorage.getItem("activeshop");
            if (token) {
                const decoded = jwtDecode(token) as any;
                console.log(decoded)
                var privileges = [];
                var roles: string[] = decoded["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
                var ownerAccess: string[] = decoded["FinancialService"]["OwnerAccess"]
                var delegatedAccess: { ownerId: string, permissions: string[] }[] = decoded["FinancialService"]["DelegatedAccess"]


                var currentShopDelegatedAccess = delegatedAccess.filter(shop => shop.ownerId === shopId)[0];
                if (currentShopDelegatedAccess)
                    privileges = currentShopDelegatedAccess.permissions

                if (roles.indexOf("SUPER_ADMIN") >= 0) {
                    privileges.push("ADMINISTRATOR");
                }

                if (ownerAccess.indexOf(shopId) >= 0)
                    privileges.push("ADMINISTRATOR");

                var userInfo: ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto = {
                    displayName: decoded.DisplayName,
                    userName: decoded["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
                    hasTwoFactor: decoded.TwoFactorEnabled,
                    privileges: privileges,
                    roles: roles
                };
                return Promise.resolve({ data: userInfo });
            } else
                return Promise.reject("token not exist");
        } catch {
            return Promise.reject("token not exist");
        }

    }

    getUserInformationComplete(): Promise<IUserInformationDTO> {
        return mapToDataOnly<IUserInformationDTO>(UserInformationApiFactory().identityUserInformationGet());
    }

    updateUserInformation(userInformation: IUpdateUserInformation): Promise<any> {
        return mapToDataOnly(UserInformationApiFactory().identityUserInformationPut(userInformation));
    }

    changePassword(data: IChangePasswordDTO): Promise<any> {
        return mapToDataOnly(PasswordApiFactory().identityPasswordResetPost(data));
    }

    getUserSecurityInformation(): Promise<ISecurityInformation> {
        return mapToDataOnly<ISecurityInformation>(SecurityApiFactory().identitySecurityGet());
    }

    generateTelegramBotToken(): Promise<string> {
        return mapToDataOnly<string>(SocialNetworkApiFactory().identitySocialNetworkGet())
    }
}

interface ISecurityInformation {
    isTwoFactorEnabled: boolean
    isEmailConfirmed: boolean
}