import React from "react";
import { TableCell, TableRow } from "@mui/material";
import CounterView from "../../../../shared/counter/CounterView";
import TruncateString from "../../../../utilities/truncate-string/TruncateString";

export default class CartItem extends React.Component<{ key: string, title: string, count: number, inventoryCount: number, unitPrice: number, imageSrc: string, onDelete: () => void, onChangeCount: (count: number) => void }> {
    render(): React.ReactNode {
        return <TableRow
            className="CartItem"
            key={this.props.key}
        >
            <TableCell align="center"><TruncateString text={this.props.title} maxLength={25} /></TableCell>
            <TableCell align="center"><TruncateString text={this.props.title} maxLength={25} /></TableCell>
            <TableCell align="center">
                <CounterView count={this.props.count} inventoryCount={this.props.inventoryCount} onChangeCount={(count) => this.props.onChangeCount(count)} onDelete={this.props.onDelete} />
            </TableCell>
            <TableCell align="center"><span className="font-bold">{this.props.unitPrice} $</span></TableCell>
        </TableRow>
    }
}