import { InventoryShopBasePlanStatusEnum } from "../../components/inventory/Enums/InventoryShopBasePlanStatusEnum";
import { mapToDataOnly } from "../common/HttpUtility";
import { ApiGiftGatewayCommonEnumsSupplyMethods, OrderProductApiFactory, ProductApiFactory, ProductCatalogApiFactory, ProductPriceApiFactory } from "../../generated-api";

export default class OrderProductService {
    getStatistics(productId: string): Promise<OrderStatisticsDTO> {
        return mapToDataOnly<OrderStatisticsDTO>(OrderProductApiFactory().sellerOrderProductStatisticsProductIdGet(productId));
    }

    getProductPriceCompare(productIds: string[]): Promise<{
        productId: string,
        yourPrice: number,
        minimumOtherPrice: number,
        minimumOtherOfferPrice: number,
    }[]> {
        var params = new URLSearchParams();
        for (var productId of productIds)
            params.append("productIds", productId);

        return mapToDataOnly<any>(ProductPriceApiFactory().productPriceCompareGet(productIds));
    }

    getStatisticsList(queryString: IProductListQueryString): Promise<{ products: ProductDetails[], totalRows: number }> {
        return mapToDataOnly<any>(ProductApiFactory()
            .marketPlaceProductGet(queryString.productCategoryId, queryString.productId, queryString.search, queryString.productTitle, queryString.sortBy, queryString.onlyAvailable, queryString.statusFilter,
                queryString.pageSkip - 1, queryString.pageSize))
    }

    getExpressCatalog(queryString: IGetExpressCatalogQueryString): Promise<{ products: IExpressProductCatalogDto[] }> {
        return mapToDataOnly<any>(ProductCatalogApiFactory().productCatalogExpressGet(queryString.productCategoryId, queryString.productId, queryString.productTitleSearch));
    }

    getOrderCount(): Promise<OrderCountDTO> {
        return mapToDataOnly<OrderCountDTO>(OrderProductApiFactory().sellerOrderProductCountGet());
    }

    getPendingCount(): Promise<OrderCountDTO> {
        return mapToDataOnly<OrderCountDTO>(OrderProductApiFactory().sellerOrderProductCountPendingGet());
    }

    handleOrderSuggestionSetting(dto: IOrderSuggestionUpdateSettingDTO[]): Promise<{}> {
        return mapToDataOnly<{}>(OrderProductApiFactory().sellerOrderProductOrderSuggestionHandlePut(dto));
    }
}

export interface IProductListQueryString {
    productCategoryId?: string,
    fetchLimit?: number,
    sortBy?: string
    search?: string
    productId?: string
    productTitle?: string
    onlyAvailable?: boolean
    statusFilter?: any
    pageSkip: number,
    pageSize: number
}


export interface IGetExpressCatalogQueryString {
    productCategoryId?: string,
    productId?: string
    productTitleSearch?: string
}



export interface IOrderSuggestionUpdateSettingDTO {
    productId: string;
    hasStar: boolean;
    hasPendingOnly: boolean;
}

export interface OrderStatisticsDTO {
    dailyAverageCount: number
}

export interface ProductDetails {
    productId: string;
    categoryId: string;
    title: string;
    realPrice: number;
    createDate: string;
    currencyId: string;
    imageId: string | null;
    imageSrc: string | null;
    totalSoldCount: number;
    status: InventoryShopBasePlanStatusEnum;
    availableInventory: number;
    minimumInventory: number;
    dailySoldCountAverage: number;
    requiredCount: number;
    buyPendingCount: number;
    isPinned: boolean;
    buyWhenNeededOnly: boolean;
    buySuggestionCount: number;
}


export interface IExpressProductCatalogDto {
    id: string;
    categoryId: string;
    title: string;
    realPrice: number;
    createDate: string;
    currencyId: string;
    imageSrc: string | null;
    supplyMethod: ApiGiftGatewayCommonEnumsSupplyMethods;
}
interface OrderCountDTO {
    count: number
}