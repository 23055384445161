import { GridResult } from "../../components/Grid/Grid.models";
import { ICreateReportDTO } from "../../components/orders/OrderItemReportModal/OrderItemReportModal.models";
import { mapToDataOnly } from "../common/HttpUtility";
import { IBuyOrderItemsFilters, IOrderItemsQuery } from "../../components/orders/OrdersItems/OrderItemsReport.models";
import { IBuyOrderItemFilters, IBuyOrderItemQuery } from "../../components/orders/Orders/Report/OrderReport.models";
import { SelectionView } from "../common/Models";
import { PaginationQuery } from "../../components/Grid/Pagination/Pagination.models";
import CommonService from "../common/CommonService";
import { BuyOrderListItem, ISellOrdersPaginationFilters } from "../../components/orders/Orders/OrdersList/OrdersList.models";
import { BuyOrderListItemFilters } from "../../components/orders/Orders/BuyOrder/BuyOrder.models";
import { ISellOrderItemsFilters } from "../../components/orders/Orders/sell-orders/SellOrderItems/SellOrderItems.models";
import { ApiGiftCommonEnumsOrdersOrdersOrderProductStatus,  OrderApiFactory, OrderItemApiFactory, OrderItemReportApiFactory, OrderProductApiFactory, OrderReportApiFactory, ProductApiFactory } from "../../generated-api";
import { ToLocaleDate } from "../../components/utilities/HybridMoment";

export default class OrdersService {
    download(id: string, fileName: string): Promise<any> {
        return OrderApiFactory().buyerOrderDetailsFullExcelOrderIdGet(id, { responseType: 'blob' }).then(({ data }) => {
            const downloadUrl = window.URL.createObjectURL(new Blob([data as any]));
            const link = document.createElement('a');
            link.href = downloadUrl;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            link.remove();
        })
    }

    order(toStock: boolean): Promise<string> {
        return mapToDataOnly<string>(OrderApiFactory().buyerOrderPost({addToInventory:toStock}))
    }

    getOrderItems(orderId: string, isSafeMode: boolean) {
        if (isSafeMode)
            return mapToDataOnly<{}>(OrderApiFactory().buyerOrderDetailsFullGet(orderId, undefined, undefined, undefined, 0, 200))
        else
            return mapToDataOnly<{}>(OrderApiFactory().buyerOrderDetailsGet(orderId, undefined, undefined, undefined, 0, 200));
    }
    getBuyOrders(filters: IBuyOrderItemFilters, paginationQuery: PaginationQuery): Promise<GridResult<BuyOrderListItem>> {
        return mapToDataOnly<GridResult<BuyOrderListItem>>(OrderReportApiFactory()
            .buyerOrderReportGet(ToLocaleDate(filters.fromDate), ToLocaleDate(filters.toDate), filters.status, filters.type, filters.orderNumber, filters.productId, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }
    getSellOrders(filters: ISellOrdersPaginationFilters, paginationQuery: PaginationQuery): Promise<GridResult<SellerOrderRowDTO>> {
        return mapToDataOnly<GridResult<SellerOrderRowDTO>>(OrderApiFactory().sellerOrderGet(filters.status, filters.orderNumber, filters.productId, filters.buyerName, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }
    getSellOrderDetails(orderId: string): Promise<SellerOrderDetailsDTO> {
        return mapToDataOnly<SellerOrderDetailsDTO>(OrderApiFactory().sellerOrderOrderIdGet(orderId));
    }
    getSellOrderDetailProductInfo(query: GetPagedOrderProductDetailsQueryString, fullInformation?: boolean): Promise<GridResult<SellerOrderProductDetailsRowDTO>> {
        return mapToDataOnly<GridResult<SellerOrderProductDetailsRowDTO>>(
            fullInformation === true ?
                OrderApiFactory().sellerOrderDetailsFullGet(query.orderId, query.productId, query.information, query.status as ApiGiftCommonEnumsOrdersOrdersOrderProductStatus, query.pageskip - 1, query.pagesize)
                : OrderApiFactory().sellerOrderDetailsGet());
    }

    getDetails(orderId: string) {
        return mapToDataOnly<{}>(OrderApiFactory().buyerOrderOrderIdGet(orderId));
    }

    getStatistics(shopId: string) {
        return mapToDataOnly<OrderStatisticsDto>(OrderApiFactory().marketPlaceOrderStatisticsShopIdGet(shopId));
    }

    getSaleRelativeStatistics(shopId: string) {
        return mapToDataOnly<OrderRelativeStatisticsDto>(OrderApiFactory().marketPlaceOrderRelativeStatisticsSaleShopIdGet(shopId));
    }

    getBuyRelativeStatistics(shopId: string) {
        return mapToDataOnly<OrderRelativeStatisticsDto>(OrderApiFactory().marketPlaceOrderRelativeStatisticsBuyShopIdGet(shopId));
    }

    getOrderProducts(query: IOrderItemsQuery): Promise<GridResult<BuyOrderListItem>> {
        var pageskip = query.pageskip - 1
        return mapToDataOnly<GridResult<BuyOrderListItem>>(
            OrderProductApiFactory().buyerOrderProductGet(query.information, undefined, query.title, query.orderNumber, pageskip, query.pagesize))
    }

    getOrderProductsFullInformation(query: IOrderItemsQuery): Promise<GridResult<BuyOrderListItem>> {
        return mapToDataOnly<GridResult<BuyOrderListItem>>(OrderProductApiFactory()
            .buyerOrderProductFullInformationGet(query.information, undefined, query.title, query.orderNumber, query.pageskip - 1, query.pagesize));
    }

    sendReport(dto: ICreateReportDTO): Promise<{}> {
        return mapToDataOnly<{}>(OrderProductApiFactory().buyerOrderProductSupportPost({ ...dto }));
    }

    getBuyOrderItems(filters: IBuyOrderItemsFilters, paginationQuery: PaginationQuery): Promise<GridResult<BuyOrderListItem>> {
        return mapToDataOnly<GridResult<BuyOrderListItem>>(OrderItemReportApiFactory()
            .buyerOrderItemReportGet(ToLocaleDate(filters.fromDate), ToLocaleDate(filters.toDate), filters.productId, filters.orderNumber, filters.title,
                filters.information, filters.status, filters.orderType, ToLocaleDate(filters.deliveryDate), filters.withFullInformation, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    getSellOrderItems(filters: ISellOrderItemsFilters, paginationQuery: PaginationQuery): Promise<GridResult<ISellOrderItemTableRow>> {
        return mapToDataOnly<GridResult<ISellOrderItemTableRow>>(OrderItemApiFactory().orderItemSellGet(filters.inventorySafeInformation, paginationQuery.pageskip - 1, paginationQuery.pagesize));
    }

    getBuyOrdersReport(query: IBuyOrderItemQuery): Promise<GridResult<BuyOrderListItem>> {
        return mapToDataOnly<GridResult<BuyOrderListItem>>(OrderReportApiFactory()
            .buyerOrderReportGet(ToLocaleDate(query.fromDate), ToLocaleDate(query.toDate), query.status, undefined, undefined, undefined, query.pageskip - 1, query.pagesize));
    }

    getProductsTitleValues(): Promise<SelectionView[]> {
        return mapToDataOnly<SelectionView[]>(ProductApiFactory().buyerProductTitleValueGet());
    }

    downloadExcelBuyOrderItems(filters: IBuyOrderItemsFilters): Promise<any> {
        var commonService = new CommonService();

        return commonService.export(OrderItemReportApiFactory()
            .buyerOrderItemReportExcelGet(ToLocaleDate(filters.fromDate), ToLocaleDate(filters.toDate),
                filters.productId, filters.orderNumber, filters.title, filters.information, filters.status, filters.orderType, ToLocaleDate(filters.deliveryDate), filters.withFullInformation, 0, 1), 'order-items.xlsx')
    }

    downloadExcelBuyOrders(filters: BuyOrderListItemFilters): Promise<any> {
        var commonService = new CommonService();

        return commonService.export(
            OrderReportApiFactory().buyerOrderReportExcelGet(ToLocaleDate(filters.fromDate), ToLocaleDate(filters.toDate), filters.status, filters.type, filters.orderNumber, filters.productId, 0, 1)
            , 'orders.xlsx')
    }
}


export interface OrderStatisticsDto {
    saleOrderItemCount: number
    saleReviewRequestPercent: number
    buyOrderItemCount: number
    buyReviewRequestPercent: number
}
export interface OrderRelativeStatisticsDto {
    totalRelativeOrderItemCount: string
    totalReviewRequestPercent: number
    orderItemCount: number
    reviewRequestPercent: number
}

export interface SellerOrderRowDTO {
    id: string;
    totalAmount: number;
    totalShareAmount: number;
    totalCashoutAmount: number;
    createAt: string;
    buyerName: string;
    buyerId: string;
    orderNumber: string;
    status: number;
}

export interface SellerOrderDetailsProductDTO {
    productId: string;
    title: string;
    count: number;
    amount: number;
    shareAmount: number;
    cashoutAmount: number;
    isComplete: boolean;
    deliveredCount: number;
    refundCount: number;
    pendingCount: number;
    unitPrice: number;
}

export interface SellerOrderDetailsDTO {
    orderNumber: string;
    status: number;
    orderDate: Date;
    buyerTitle: string;
    buyerId: string;
    totalAmount: number;
    totalCashoutAmount: number;
    totalRefund: number;
    totalShare: number;
    products: SellerOrderDetailsProductDTO[];
}


export interface SellerOrderProductDetailsRowDTO {
    informations: string;
    deliveryAt?: string | null;
    status: number;
}


export interface GetPagedOrderProductDetailsQueryString extends PaginationQuery {
    orderId: string;
    productId: string;
    information?: string;
    status?: number;
}


export interface ISellOrderItemTableRow {
    reviewRequestId: string;
    productId: string;
    orderNumber: string;
    productTitle: string;
    orderDate: string;
    informations: string[]
    status: number;
    sellerPrice: number;
}