import React, { FormEvent } from "react";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { Modal, Button, OverlayTrigger, Spinner, Tooltip, Form, Popover } from "react-bootstrap"
import { BiHide, BiShow } from "react-icons/bi";
import { MdOutlineContentCopy } from "react-icons/md";
import OrderProductStatusBadge, { OrderProductStatusEnum } from "../../Enums/OrderProductStatusEnum";
import HybridMoment from "../../../utilities/HybridMoment";
import OrderReviewRequest from "../../OrderReviewRequest/OrderReviewRequest";
import ModalComponent from "../../../shared/modal/Modal";
import { OrderItemApiFactory } from "../../../../generated-api";

interface OrderItemView {
    deliveredAt: string
    reviewRequestSupportTicketId: string
    id: string
    informations: string
    isSold: boolean
    orderType: number
    status: number
    title: string
    supplyIdentity: string
    supplyMethod: 0 | 1
}

interface IdentitySupply {
    id: string,
    identity: string
}

interface OrderItemsState { selectedIdentity?: IdentitySupply }

interface OrderItemsProps {
    items: OrderItemView[];
    isSafeMode: boolean
    changeSafeMode: () => void
    loadTable: () => void
}

export default class OrderItemsTableView extends React.Component<OrderItemsProps, OrderItemsState> {
    constructor(props: OrderItemsProps) {
        super(props)
        this.state = {}
    }


    applyIdentitySupply = (e: FormEvent) => {
        e.preventDefault();
        OrderItemApiFactory().orderItemIdSupplyIdentityPut(this.state.selectedIdentity.id, { identity: this.state.selectedIdentity.identity }).then(() => {
            this.props.loadTable()
            this.setState({ selectedIdentity: undefined })
        })
    }


    render(): React.ReactNode {
        return <>
            <TableContainer className="grid mt-lg">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">#</TableCell>
                            <TableCell align="center">Title</TableCell>
                            <TableCell align="center">Status</TableCell>
                            <TableCell align="center">Information</TableCell>
                            <TableCell align="center">Delivery Time</TableCell>
                            <TableCell align="center">Show</TableCell>
                            <TableCell align="center">Report</TableCell>
                            <TableCell align="center">Copy</TableCell>
                            <TableCell align="center">Supply Identity</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            this.props.items.map((item, index) =>
                                <TableRow>
                                    <TableCell align="center">{index + 1}</TableCell>
                                    <TableCell align="center">{item.title}</TableCell>
                                    <TableCell align="center">{OrderProductStatusBadge(item.status)}</TableCell>
                                    <TableCell align="center">{item.informations && this.informationNormalize(item.informations)}</TableCell>
                                    <TableCell align="center">{item.deliveredAt && HybridMoment(item.deliveredAt)}</TableCell>
                                    <TableCell align="center">
                                        { item.supplyMethod === 0 && (this.props.isSafeMode ?
                                            <BiHide size={22} className="pointer text-grey" onClick={this.props.changeSafeMode} />
                                            : <BiShow size={22} className="pointer text-grey" onClick={this.props.changeSafeMode} />
                                        )}
                                    </TableCell>
                                    <TableCell align="center">
                                        <OrderReviewRequest orderItemId={item.id} reviewRequestSupportTicketId={item.reviewRequestSupportTicketId}
                                            status={item.status as OrderProductStatusEnum}
                                            onReported={() => { this.props.loadTable() }} />
                                    </TableCell>
                                    <TableCell align="center">
                                        {
                                            item.supplyMethod === 0 &&
                                            <MdOutlineContentCopy size={22} className="pointer text-grey" onClick={this.props.changeSafeMode} />
                                        }
                                    </TableCell>

                                    <TableCell align="center">
                                        {
                                            item.supplyMethod === 1 && <>
                                                {
                                                    item.supplyIdentity ?? <a href="#" onClick={() => this.setState({ selectedIdentity: { id: item.id, identity: '' } })}>
                                                        Set
                                                    </a>
                                                }
                                            </>
                                        }

                                    </TableCell>
                                </TableRow>)}
                    </TableBody>
                </Table >
            </TableContainer>

            {
                this.state.selectedIdentity && <ModalComponent
                    size="400px"
                    title={`Set Supply Identity`}
                    onClose={() => this.setState({ selectedIdentity: undefined })}
                >


                    <Form onSubmit={this.applyIdentitySupply}>
                        <Form.Group controlId="createShop.title">
                            <Form.Label>Identity:</Form.Label>
                            <Form.Control type="text" value={this.state.selectedIdentity.identity}
                                placeholder="Enter identity"
                                onChange={(e) => this.setState({ selectedIdentity: { ...this.state.selectedIdentity, identity: e.target.value } })} />
                        </Form.Group>
                        <br />
                        <br />
                        <Button variant="success" type="submit">Apply</Button>
                    </Form>
                </ModalComponent>
            }

        </>;
    }

    informationNormalize(information: string) {
        information = information.replace('["', '').replace('"]', '').replace('","', ' , ');
        return information.indexOf(',') >= 0 ? information.split(',').map((info) => <span>{info}<br /></span>) : information;
    }
}