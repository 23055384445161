import { Chip } from "@mui/material";
import ChipComponent from "../shared/Chip/Chip";

export enum OrderStatusEnum {
    Pending,
    Done,
    Payment,
    Canceled,
    Refund,
    Pricing,
    PayToSeller,
    Supply
}

export function orderStatusDisplayCaption(status: number) {
    let types = ['Pending', 'Done', 'Payment', 'Canceled', 'Refund', 'Pricing', 'PayToSeller','Supply'];
    return types.length <= status ? status : types[status];
}

export function orderStatusColor(status: number) {
    let types = ['warning', 'success', 'default', 'error', 'info', 'default', 'default','warning'];
    return types.length <= status ? 'dark' : types[status];
}

export default function orderStatusDisplayBadge(status: number) {
    return <ChipComponent size="small" type={orderStatusColor(status) as any} label={orderStatusDisplayCaption(status)} />;
}

export function orderDetailsProductStatusDisplayCaption(status: boolean) {
    let types = ['Completed', 'InProgress'];
    return status ? types[0] : types[1];
}

export function orderDetailsProductStatusColor(status: boolean) {
    let types = ['success', 'warning'];
    return status ? types[0] : types[1];
}

export function orderDetailsProductStatusDisplayBadge(status: boolean) {
    return <ChipComponent size="small" type={orderDetailsProductStatusColor(status) as any} label={orderDetailsProductStatusDisplayCaption(status)} />;
}