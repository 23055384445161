/* tslint:disable */
/* eslint-disable */
/**
 * Identity
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @interface ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
 */
export interface ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto {
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'displayName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'userName'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'hasTwoFactor'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'privileges'?: Array<string> | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto
     */
    'roles'?: Array<string> | null;
}
/**
 * 
 * @export
 * @interface AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse
 */
export interface AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse {
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse
     */
    'isVerified'?: boolean;
    /**
     * 
     * @type {Array<string>}
     * @memberof AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse
     */
    'recoveryCodes'?: Array<string> | null;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const AuthenticationCommonEnumsUserStatus = {
    NUMBER_0: 0,
    NUMBER_1: 1,
    NUMBER_2: 2
} as const;

export type AuthenticationCommonEnumsUserStatus = typeof AuthenticationCommonEnumsUserStatus[keyof typeof AuthenticationCommonEnumsUserStatus];


/**
 * 
 * @export
 * @interface AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
 */
export interface AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse {
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'lastName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'email'?: string | null;
    /**
     * 
     * @type {AuthenticationCommonEnumsUserStatus}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'status'?: AuthenticationCommonEnumsUserStatus;
    /**
     * 
     * @type {boolean}
     * @memberof AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse
     */
    'isTwoFactorEnabled'?: boolean;
}


/**
 * 
 * @export
 * @interface IdentityCommonDtosChangeRegisterEmailDTO
 */
export interface IdentityCommonDtosChangeRegisterEmailDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeRegisterEmailDTO
     */
    'newEmail': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeRegisterEmailDTO
     */
    'returnURL'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosChangeRegisterEmailDTO
     */
    'oldEmail'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosConfirmEmailDTO
 */
export interface IdentityCommonDtosConfirmEmailDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosConfirmEmailDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosConfirmEmailDTO
     */
    'returnURL'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosForgotPasswordDTO
 */
export interface IdentityCommonDtosForgotPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosForgotPasswordDTO
     */
    'email'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosRecoveryTwoFactorDTO
 */
export interface IdentityCommonDtosRecoveryTwoFactorDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRecoveryTwoFactorDTO
     */
    'email'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRecoveryTwoFactorDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof IdentityCommonDtosRecoveryTwoFactorDTO
     */
    'disableTwoFactor'?: boolean;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosRegisterUserDTO
 */
export interface IdentityCommonDtosRegisterUserDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'email': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'password': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'firstname': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosRegisterUserDTO
     */
    'lastname': string;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosResetPasswordDTO
 */
export interface IdentityCommonDtosResetPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosResetPasswordDTO
     */
    'currentPassword': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosResetPasswordDTO
     */
    'newPassword': string;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosSignInDTO
 */
export interface IdentityCommonDtosSignInDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosSignInDTO
     */
    'userName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosSignInDTO
     */
    'password'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosSignInDTO
     */
    'totp'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosUpdateMyUserInformationDTO
 */
export interface IdentityCommonDtosUpdateMyUserInformationDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosUpdateMyUserInformationDTO
     */
    'firstName'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosUpdateMyUserInformationDTO
     */
    'lastName'?: string | null;
}
/**
 * 
 * @export
 * @interface IdentityCommonDtosVerifyForgotPasswordDTO
 */
export interface IdentityCommonDtosVerifyForgotPasswordDTO {
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosVerifyForgotPasswordDTO
     */
    'token': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosVerifyForgotPasswordDTO
     */
    'newPassowrd': string;
    /**
     * 
     * @type {string}
     * @memberof IdentityCommonDtosVerifyForgotPasswordDTO
     */
    'userId': string;
}

/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountInformationsGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Account/Informations`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountPost: async (identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosSignInDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAccountInformationsGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAccountInformationsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.identityAccountInformationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityAccountPost(identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityAccountPost(identityCommonDtosSignInDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.identityAccountPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountInformationsGet(options?: AxiosRequestConfig): AxiosPromise<ApiGiftGatewayCommonDTOsShopsShopsShopUserInformationDto> {
            return localVarFp.identityAccountInformationsGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityAccountPost(identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityAccountPost(identityCommonDtosSignInDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public identityAccountInformationsGet(options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).identityAccountInformationsGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosSignInDTO} [identityCommonDtosSignInDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public identityAccountPost(identityCommonDtosSignInDTO?: IdentityCommonDtosSignInDTO, options?: AxiosRequestConfig) {
        return AccountApiFp(this.configuration).identityAccountPost(identityCommonDtosSignInDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PasswordApi - axios parameter creator
 * @export
 */
export const PasswordApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotPost: async (returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Password/Forgot`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosForgotPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotVerifyPost: async (returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Password/Forgot/Verify`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosVerifyForgotPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordResetPost: async (identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Password/Reset`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosResetPasswordDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PasswordApi - functional programming interface
 * @export
 */
export const PasswordApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PasswordApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityPasswordForgotPost(returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityPasswordForgotPost(returnURL, identityCommonDtosForgotPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordApi.identityPasswordForgotPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityPasswordForgotVerifyPost(returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityPasswordForgotVerifyPost(returnURL, identityCommonDtosVerifyForgotPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordApi.identityPasswordForgotVerifyPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityPasswordResetPost(identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityPasswordResetPost(identityCommonDtosResetPasswordDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PasswordApi.identityPasswordResetPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PasswordApi - factory interface
 * @export
 */
export const PasswordApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PasswordApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotPost(returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityPasswordForgotPost(returnURL, identityCommonDtosForgotPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordForgotVerifyPost(returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityPasswordForgotVerifyPost(returnURL, identityCommonDtosVerifyForgotPasswordDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityPasswordResetPost(identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityPasswordResetPost(identityCommonDtosResetPasswordDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PasswordApi - object-oriented interface
 * @export
 * @class PasswordApi
 * @extends {BaseAPI}
 */
export class PasswordApi extends BaseAPI {
    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosForgotPasswordDTO} [identityCommonDtosForgotPasswordDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public identityPasswordForgotPost(returnURL?: string, identityCommonDtosForgotPasswordDTO?: IdentityCommonDtosForgotPasswordDTO, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).identityPasswordForgotPost(returnURL, identityCommonDtosForgotPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosVerifyForgotPasswordDTO} [identityCommonDtosVerifyForgotPasswordDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public identityPasswordForgotVerifyPost(returnURL?: string, identityCommonDtosVerifyForgotPasswordDTO?: IdentityCommonDtosVerifyForgotPasswordDTO, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).identityPasswordForgotVerifyPost(returnURL, identityCommonDtosVerifyForgotPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosResetPasswordDTO} [identityCommonDtosResetPasswordDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PasswordApi
     */
    public identityPasswordResetPost(identityCommonDtosResetPasswordDTO?: IdentityCommonDtosResetPasswordDTO, options?: AxiosRequestConfig) {
        return PasswordApiFp(this.configuration).identityPasswordResetPost(identityCommonDtosResetPasswordDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * RegisterApi - axios parameter creator
 * @export
 */
export const RegisterApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [token] 
         * @param {string} [returnURL] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterConfirmGet: async (id?: string, token?: string, returnURL?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register/Confirm`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)

            if (id !== undefined) {
                localVarQueryParameter['Id'] = id;
            }

            if (token !== undefined) {
                localVarQueryParameter['Token'] = token;
            }

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        identityRegisterPost: async (returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosRegisterUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterPut: async (identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosChangeRegisterEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterUserPost: async (returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Register/User`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)

            if (returnURL !== undefined) {
                localVarQueryParameter['returnURL'] = returnURL;
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosRegisterUserDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * RegisterApi - functional programming interface
 * @export
 */
export const RegisterApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = RegisterApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [token] 
         * @param {string} [returnURL] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityRegisterConfirmGet(id?: string, token?: string, returnURL?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterConfirmGet(id, token, returnURL, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterConfirmGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async identityRegisterPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterPost(returnURL, identityCommonDtosRegisterUserDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityRegisterUserPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityRegisterUserPost(returnURL, identityCommonDtosRegisterUserDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['RegisterApi.identityRegisterUserPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * RegisterApi - factory interface
 * @export
 */
export const RegisterApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = RegisterApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [id] 
         * @param {string} [token] 
         * @param {string} [returnURL] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterConfirmGet(id?: string, token?: string, returnURL?: string, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterConfirmGet(id, token, returnURL, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        identityRegisterPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [returnURL] 
         * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityRegisterUserPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityRegisterUserPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * RegisterApi - object-oriented interface
 * @export
 * @class RegisterApi
 * @extends {BaseAPI}
 */
export class RegisterApi extends BaseAPI {
    /**
     * 
     * @param {string} [id] 
     * @param {string} [token] 
     * @param {string} [returnURL] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterConfirmGet(id?: string, token?: string, returnURL?: string, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterConfirmGet(id, token, returnURL, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosChangeRegisterEmailDTO} [identityCommonDtosChangeRegisterEmailDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO?: IdentityCommonDtosChangeRegisterEmailDTO, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterPut(identityCommonDtosChangeRegisterEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {string} [returnURL] 
     * @param {IdentityCommonDtosRegisterUserDTO} [identityCommonDtosRegisterUserDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof RegisterApi
     */
    public identityRegisterUserPost(returnURL?: string, identityCommonDtosRegisterUserDTO?: IdentityCommonDtosRegisterUserDTO, options?: AxiosRequestConfig) {
        return RegisterApiFp(this.configuration).identityRegisterUserPost(returnURL, identityCommonDtosRegisterUserDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SecurityApi - axios parameter creator
 * @export
 */
export const SecurityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityConfirmEmailPut: async (identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Security/ConfirmEmail`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosConfirmEmailDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/Security`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SecurityApi - functional programming interface
 * @export
 */
export const SecurityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SecurityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityApi.identitySecurityConfirmEmailPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identitySecurityGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identitySecurityGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SecurityApi.identitySecurityGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SecurityApi - factory interface
 * @export
 */
export const SecurityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SecurityApiFp(configuration)
    return {
        /**
         * 
         * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identitySecurityGet(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identitySecurityGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SecurityApi - object-oriented interface
 * @export
 * @class SecurityApi
 * @extends {BaseAPI}
 */
export class SecurityApi extends BaseAPI {
    /**
     * 
     * @param {IdentityCommonDtosConfirmEmailDTO} [identityCommonDtosConfirmEmailDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO?: IdentityCommonDtosConfirmEmailDTO, options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).identitySecurityConfirmEmailPut(identityCommonDtosConfirmEmailDTO, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SecurityApi
     */
    public identitySecurityGet(options?: AxiosRequestConfig) {
        return SecurityApiFp(this.configuration).identitySecurityGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TwoFactorApi - axios parameter creator
 * @export
 */
export const TwoFactorApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorDisablePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactor/Disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorEnablePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactor/Enable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactor`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwoFactorApi - functional programming interface
 * @export
 */
export const TwoFactorApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwoFactorApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorDisablePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorDisablePut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.identityTwoFactorDisablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorEnablePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorEnablePut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.identityTwoFactorEnablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorApi.identityTwoFactorGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TwoFactorApi - factory interface
 * @export
 */
export const TwoFactorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwoFactorApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorDisablePut(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityTwoFactorDisablePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorEnablePut(options?: AxiosRequestConfig): AxiosPromise<AuthenticationCommonCommandsEnableTwoFactorAuthenticationCommandResponse> {
            return localVarFp.identityTwoFactorEnablePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorGet(options?: AxiosRequestConfig): AxiosPromise<string> {
            return localVarFp.identityTwoFactorGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwoFactorApi - object-oriented interface
 * @export
 * @class TwoFactorApi
 * @extends {BaseAPI}
 */
export class TwoFactorApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public identityTwoFactorDisablePut(options?: AxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).identityTwoFactorDisablePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public identityTwoFactorEnablePut(options?: AxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).identityTwoFactorEnablePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorApi
     */
    public identityTwoFactorGet(options?: AxiosRequestConfig) {
        return TwoFactorApiFp(this.configuration).identityTwoFactorGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * TwoFactorRecoveryApi - axios parameter creator
 * @export
 */
export const TwoFactorRecoveryApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoveryDisablePut: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactorRecovery/Disable`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        identityTwoFactorRecoverySignInPut: async (identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/TwoFactorRecovery/SignIn`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosRecoveryTwoFactorDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * TwoFactorRecoveryApi - functional programming interface
 * @export
 */
export const TwoFactorRecoveryApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = TwoFactorRecoveryApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityTwoFactorRecoveryDisablePut(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorRecoveryDisablePut(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorRecoveryApi.identityTwoFactorRecoveryDisablePut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        async identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['TwoFactorRecoveryApi.identityTwoFactorRecoverySignInPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * TwoFactorRecoveryApi - factory interface
 * @export
 */
export const TwoFactorRecoveryApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = TwoFactorRecoveryApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityTwoFactorRecoveryDisablePut(options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityTwoFactorRecoveryDisablePut(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
         * @param {*} [options] Override http request option.
         * @deprecated
         * @throws {RequiredError}
         */
        identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * TwoFactorRecoveryApi - object-oriented interface
 * @export
 * @class TwoFactorRecoveryApi
 * @extends {BaseAPI}
 */
export class TwoFactorRecoveryApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof TwoFactorRecoveryApi
     */
    public identityTwoFactorRecoveryDisablePut(options?: AxiosRequestConfig) {
        return TwoFactorRecoveryApiFp(this.configuration).identityTwoFactorRecoveryDisablePut(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosRecoveryTwoFactorDTO} [identityCommonDtosRecoveryTwoFactorDTO] 
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     * @memberof TwoFactorRecoveryApi
     */
    public identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO?: IdentityCommonDtosRecoveryTwoFactorDTO, options?: AxiosRequestConfig) {
        return TwoFactorRecoveryApiFp(this.configuration).identityTwoFactorRecoverySignInPut(identityCommonDtosRecoveryTwoFactorDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UserInformationApi - axios parameter creator
 * @export
 */
export const UserInformationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/UserInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationPut: async (identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Identity/UserInformation`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication secret-key required
            await setApiKeyToObject(localVarHeaderParameter, "secret-key", configuration)

            // authentication access-key required
            await setApiKeyToObject(localVarHeaderParameter, "access-key", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(identityCommonDtosUpdateMyUserInformationDTO, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserInformationApi - functional programming interface
 * @export
 */
export const UserInformationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserInformationApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityUserInformationGet(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityUserInformationGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserInformationApi.identityUserInformationGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserInformationApi.identityUserInformationPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserInformationApi - factory interface
 * @export
 */
export const UserInformationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserInformationApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationGet(options?: AxiosRequestConfig): AxiosPromise<AuthenticationCommonQueriesGetUpdateUserByIdQueryQueryResponse> {
            return localVarFp.identityUserInformationGet(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options?: AxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserInformationApi - object-oriented interface
 * @export
 * @class UserInformationApi
 * @extends {BaseAPI}
 */
export class UserInformationApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInformationApi
     */
    public identityUserInformationGet(options?: AxiosRequestConfig) {
        return UserInformationApiFp(this.configuration).identityUserInformationGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {IdentityCommonDtosUpdateMyUserInformationDTO} [identityCommonDtosUpdateMyUserInformationDTO] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserInformationApi
     */
    public identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO?: IdentityCommonDtosUpdateMyUserInformationDTO, options?: AxiosRequestConfig) {
        return UserInformationApiFp(this.configuration).identityUserInformationPut(identityCommonDtosUpdateMyUserInformationDTO, options).then((request) => request(this.axios, this.basePath));
    }
}



